import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#ddb674",
    },
    text: {
      primary: "#d7ccb9",
    },
  },
  typography: {
    h3: {
      color: "#ddb674",
    },
    h5: {
      color: "#ddb674",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#22222277",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#22222288",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "black",
          textAlign: "left",
        },
      },
    },
  },
});
