import { mat4, vec3 } from "gl-matrix";

export interface Camera {
  position: vec3;
  lookAtPosition: vec3;
  upDirection: vec3;
  zNear: number;
  zFar: number;
  fovX: number;
}

export const createLookAtMat4 = (out: mat4, camera: Camera): mat4 => {
  return mat4.lookAt(
    out,
    camera.position,
    camera.lookAtPosition,
    camera.upDirection,
  );
};

export const createPerspectiveMat4 = (
  out: mat4,
  camera: Camera,
  aspect: number,
): mat4 => {
  return mat4.perspective(out, camera.fovX, aspect, camera.zNear, camera.zFar);
};
