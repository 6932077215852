import { useState, useEffect, useMemo, useRef, useCallback } from "react";

interface UseHasIntersectedResult {
  hasIntersected: boolean;
  deltaSeconds: number;
}

export const useHasIntersected = (
  element: HTMLElement | null,
): UseHasIntersectedResult => {
  const wasIntersectingRef = useRef(false);

  const [result, setResult] = useState<UseHasIntersectedResult>({
    hasIntersected: false,
    deltaSeconds: 0,
  });

  const mountDate = useMemo(() => new Date(), []);

  const handleIntersection = useCallback(() => {
    setResult({
      hasIntersected: true,
      deltaSeconds: (new Date().getTime() - mountDate.getTime()) / 1000,
    });
  }, []);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && wasIntersectingRef.current === false) {
          handleIntersection();
          wasIntersectingRef.current = true;
        }
      }),
    [],
  );

  useEffect(() => {
    if (element) {
      observer.observe(element);
    }

    return () => observer.disconnect();
  }, [element]);

  return result;
};
