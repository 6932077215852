import { Section } from "./section/Section";
import { BackgroundCanvas } from "./component/BackgroundCanvas/BackgroundCanvas";
import { FadeIn } from "./component/FadeIn";
import { Technologies } from "./section/content/Technologies";
import { ContactAndImprint } from "./section/content/ContactAndImprint";
import { AboutMe } from "./section/content/AboutMe";
import { CallToActionSpeedDial } from "./component/CallToAction";
import { ServicesOffered } from "./section/content/ServicesOffered";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Disclaimer } from "./section/content/Disclaimer";
import { ProjectCarousel } from "./section/content/ProjectCarousel";
import { Availability } from "./section/content/Availability";
import { featureToggles } from "./feature-toggles";

export const App = () => {
  return (
    <>
      <BackgroundCanvas />
      <CallToActionSpeedDial />
      <Container maxWidth={"sm"}>
        <Box paddingTop={4} paddingBottom={4}>
          <FadeIn duration={2}>
            <img src={"/image/wesseler-software-light.png"} width={"100%"} />
          </FadeIn>
        </Box>
      </Container>
      <Container maxWidth={"md"}>
        <FadeIn maxDelay={1}>
          <Section>
            <AboutMe />
          </Section>
        </FadeIn>
        <FadeIn maxDelay={1.5}>
          <Section>
            <Technologies />
          </Section>
        </FadeIn>
        <FadeIn maxDelay={2}>
          <Section>
            <ProjectCarousel />
          </Section>
        </FadeIn>
        {featureToggles.availability && (
          <FadeIn maxDelay={2}>
            <Section>
              <Availability />
            </Section>
          </FadeIn>
        )}
        <FadeIn maxDelay={2}>
          <Section>
            <ServicesOffered />
          </Section>
        </FadeIn>

        <Section>
          <ContactAndImprint />
        </Section>
        <Section>
          <Disclaimer />
        </Section>
      </Container>
    </>
  );
};
