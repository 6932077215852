import { FormattedMessage } from "react-intl";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import { SmallTitle } from "../../component/SmallTitle";

const StyledBox = styled(Box)`
  white-space: break-spaces;
`;

export const ContactAndImprint: React.FC<{}> = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <SmallTitle>
            <FormattedMessage id={"contact"} />
          </SmallTitle>
          <StyledBox>
            <FormattedMessage id={"contact.text"} />
          </StyledBox>
        </Grid>
        <Grid item sm={6}>
          <SmallTitle>
            <FormattedMessage id={"imprint"} />
          </SmallTitle>
          <StyledBox>
            <FormattedMessage id={"imprint.text"} />
          </StyledBox>
        </Grid>
      </Grid>
    </>
  );
};
