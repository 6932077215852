const isPowerOf2 = (value: number) => (value & (value - 1)) === 0;

export const createTexture = (gl: WebGLRenderingContext): WebGLTexture => {
  const texture = gl.createTexture();

  if (!texture) {
    throw new Error("Failed to create web gl texture");
  }

  return texture;
};

export const loadTexture = (
  gl: WebGLRenderingContext,
  textureUrl: string,
): WebGLTexture => {
  const texture = createTexture(gl);

  // Use a transparent one-pixel texture while the real texture is loading
  gl.bindTexture(gl.TEXTURE_2D, texture);

  const pixels = new Uint8Array([0, 0, 0, 0]); // Transparent pixel

  gl.texImage2D(
    gl.TEXTURE_2D,
    0, // Mipmap level
    gl.RGBA, // Internal format
    1, // Width
    1, // Height
    0, // Border
    gl.RGBA, // Source format
    gl.UNSIGNED_BYTE, // Source type
    pixels, // Data
  );

  // Load real texture
  const image = new Image();
  image.onload = () => {
    console.debug("gl :: texture :: onload");

    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0, // Mipmap Level
      gl.RGBA, // Internal format
      gl.RGBA, // Source format
      gl.UNSIGNED_BYTE, // Source type
      image,
    );

    // Use mipmaps or linear filtering
    if (isPowerOf2(image.width) && isPowerOf2(image.height)) {
      gl.generateMipmap(gl.TEXTURE_2D);
    } else {
      console.warn(
        "gl :: texture :: Texture suze is not a power of two",
        textureUrl,
      );
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    }
  };
  image.src = textureUrl;

  return texture;
};
