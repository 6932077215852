import { pixelsPerRem } from "../util";
import { FormattedMessage, useIntl } from "react-intl";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import GroupsIcon from "@mui/icons-material/Groups";
import EmailIcon from "@mui/icons-material/Email";

export const CallToActionSpeedDial: React.FC = () => {
  const intl = useIntl();

  const emailAddress = intl.formatMessage({ id: "contact.me.emailAddress" });

  return (
    <SpeedDial
      ariaLabel={"speedDial"}
      icon={<QuestionAnswerIcon />}
      sx={{
        position: "fixed",
        right: pixelsPerRem * 2,
        bottom: pixelsPerRem * 2,
      }}
    >
      <SpeedDialAction
        icon={<GroupsIcon />}
        onClick={() =>
          window.open(
            `https://teams.microsoft.com/l/chat/0/0?users=${emailAddress}`,
          )
        }
        tooltipTitle={<FormattedMessage id={"contact.me.teams"} />}
      />
      <SpeedDialAction
        icon={<EmailIcon />}
        onClick={() => window.open(`mailto:${emailAddress}`)}
        tooltipTitle={<FormattedMessage id={"contact.me.email"} />}
      />
    </SpeedDial>
  );
};
