export const backgroundFragmentShaderSource = `
    precision mediump float;

    varying vec2 vTexCoords;

    uniform float uScrollYPercentage;

    
    vec4 foregroundColor = vec4(242.0 / 750.0, 156.0 / 750.0, 29.0 / 750.0, 1.0);
    vec4 backgroundColor = vec4(49.0 / 2000.0, 45.0 / 2000.0, 50.0 / 2000.0, 1.0);

    void main() {
        vec2 center = vec2(0.25 + 0.25 * uScrollYPercentage, 0.25  + 0.25 * uScrollYPercentage);

        vec2 diff = abs(center - vTexCoords);
        float colorDistance = length(diff);
        vec3 color = mix(foregroundColor.rgb, backgroundColor.rgb, colorDistance);
        
        gl_FragColor = vec4(color, 1.0);
    }
`;

export const fragmentShaderSource = `
    precision mediump float;

    uniform vec4 uColor;
    uniform sampler2D uTexture;

    varying vec2 vTexCoords;

    void main() {
        gl_FragColor =  texture2D(uTexture, vTexCoords) * uColor;

        if (gl_FragColor.a <= 0.1) {
            discard;
        }
    }
`;

export const postProcessingFragmentShaderSource = `
    precision mediump float;

    uniform vec4 uColor;
    uniform sampler2D uTexture;

    varying vec2 vTexCoords;

    void main() {
        vec4 color = texture2D(uTexture, vTexCoords * vec2(1.0, 1.0));
        gl_FragColor =  color;
    }
`;
