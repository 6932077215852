export const vertexShaderSource = `
    attribute vec3 aPosition;
    attribute vec2 aTexCoords;

    varying vec2 vTexCoords;

    uniform mat4 uViewMatrix;
    uniform mat4 uWorldMatrix;
    uniform mat4 uProjectionMatrix;

    void main() {
        vTexCoords = aTexCoords;
        gl_Position = uProjectionMatrix * uViewMatrix * uWorldMatrix * vec4(aPosition, 1.0);
    }
`;

export const orthogonalVertexShaderSource = `
    attribute vec3 aPosition;
    attribute vec2 aTexCoords;

    varying vec2 vTexCoords;

    uniform mat4 uProjectionMatrix;

    void main() {
        vTexCoords = aTexCoords;
        gl_Position = uProjectionMatrix * vec4(aPosition, 1.0);
    }
`;
