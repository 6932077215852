import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

export const Section: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box marginBottom={4}>
      <Box
        height={10}
        sx={{
          background: "linear-gradient(to right, #d7ccb955, #312d32bb)",
          borderRadius: "15px 15px 0px 0px",
        }}
      />
      <Paper>
        <Box padding={2}>{children}</Box>
      </Paper>
    </Box>
  );
};
