import { FormattedMessage, useIntl } from "react-intl";
import { pixelsPerRem } from "../../util";
import styled from "@emotion/styled";
import { Title } from "../../component/Title";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Carousel, { ResponsiveType } from "react-multi-carousel";
import Link from "@mui/material/Link";

interface Project {
  readonly key: string;
  readonly imageUrl: string;
  readonly imageCopyright: string;
  readonly content: React.ReactNode;
}

const projects = [
  {
    key: "meisterplan",
    imageUrl: "/image/project/Meisterplan.png",
    imageCopyright: "Meisterplan - itdesign GmbH",
    content: (
      <small>
        <FormattedMessage id={"project.meisterplan.text"} />
      </small>
    ),
  },
  {
    key: "onlinePoliceThuringia",
    imageUrl: "/image/project/thuringia-logo.png",
    imageCopyright: "Freistaat Thüringen",
    content: (
      <small>
        <FormattedMessage id={"project.onlinePoliceThuringia.text"} />
      </small>
    ),
  },
  {
    key: "meinePegel",
    imageUrl: "/image/project/meine-pegel.webp",
    imageCopyright: "Umweltministerium Baden-Württemberg",
    content: (
      <small>
        <FormattedMessage id={"project.meinePegel.text"} />
      </small>
    ),
  },
  {
    key: "meineUmwelt",
    imageUrl: "/image/project/meine-umwelt.webp",
    imageCopyright: "Umweltministerium Baden-Württemberg",
    content: (
      <small>
        <FormattedMessage id={"project.meineUmwelt.text"} />
      </small>
    ),
  },
  {
    key: "videoPuzzle",
    imageUrl: "/image/project/videopuzzle.png",
    imageCopyright: "Wesseler Software",
    content: (
      <small>
        <FormattedMessage id={"project.videoPuzzle.text"} />
      </small>
    ),
  },
] as const satisfies readonly Project[];

const StyledCard = styled(Card)`
  transition: transform 0.1s;

  :hover {
    transform: scale(1.03);
  }

  margin-left: ${pixelsPerRem * 0.5}px;
  margin-right: ${pixelsPerRem * 0.5}px;
`;

const responsive: ResponsiveType = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const StyledLink = styled(Link)`
  .showOnHover {
    display: none;
  }

  :hover .showOnHover {
    display: inline;
  }
`;

export const ProjectCarousel = () => {
  const intl = useIntl();

  return (
    <>
      <Title>
        <FormattedMessage id={"projects"} />
      </Title>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={7000}
        infinite={true}
        draggable={false}
        showDots={false}
      >
        {projects.map((p, i) => (
          <StyledCard key={p.key}>
            <CardMedia
              component={"img"}
              image={p.imageUrl}
              sx={{ width: "100%", maxHeight: pixelsPerRem * 10 }}
            />
            <CardContent>
              <small>© {p.imageCopyright}</small>
              <StyledLink
                href={intl.formatMessage({ id: `project.${p.key}.url` })}
                target={"_blank"}
              >
                <Typography variant={"h5"} gutterBottom>
                  <FormattedMessage id={`project.${p.key}`} />{" "}
                  <span className={"showOnHover"}>↗</span>
                </Typography>
              </StyledLink>
              {p.content}
            </CardContent>
          </StyledCard>
        ))}
      </Carousel>
    </>
  );
};
