import React, { useState } from "react";
import { useHasIntersected } from "../hook/useHasIntersected";
import styled from "@emotion/styled";
import { fadeInCss } from "../animation/fade-in";
import Box from "@mui/material/Box";

const StyledBox = styled(Box)`
  ${fadeInCss}
`;

interface FadeInProps {
  duration?: number;
  maxDelay?: number;
}

export const FadeIn: React.FC<React.PropsWithChildren<FadeInProps>> = ({
  children,
  duration = 1,
  maxDelay = 0,
}) => {
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const { hasIntersected, deltaSeconds } = useHasIntersected(element);

  return (
    <StyledBox
      ref={setElement}
      running={hasIntersected}
      duration={duration}
      delay={Math.max(0, maxDelay - deltaSeconds)}
    >
      {children}
    </StyledBox>
  );
};
