import React from "react";
import Calendar, { TileArgs, TileDisabledFunc } from "react-calendar";

import "react-calendar/dist/Calendar.css";
import { FormattedDate, FormattedMessage } from "react-intl";
import { Title } from "../../component/Title";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { Tooltip } from "@mui/material";

type Period = [Date | undefined, Date | undefined];

const unavailablePeriods: Period[] = [[undefined, new Date("2024-04-01")]];

const toZeroHours = (date: Date) => {
  const clone = new Date(date.getTime());
  clone.setHours(0, 0, 0, 0);
  return clone;
};

const isInPeriod = (date: Date, period: Period) => {
  const zeroDate = toZeroHours(date);

  const start = period[0];
  const end = period[1];

  const isBeforeStart =
    start && toZeroHours(start).getTime() > zeroDate.getTime();
  const isAfterEnd = end && toZeroHours(end).getTime() - 1 < zeroDate.getTime();

  return !(isBeforeStart || isAfterEnd);
};

const isInAvailablePeriod = (date: Date) =>
  !unavailablePeriods.some((p) => isInPeriod(date, p));

const tileDisabled: TileDisabledFunc = ({ date }) => !isInAvailablePeriod(date);

const firstAvailableDay = (): Date => {
  let result = new Date();

  // Push result to the next end date, if it is inside
  for (const period of unavailablePeriods) {
    if (isInPeriod(result, period) && period[1]) {
      result = period[1];
    }
  }

  return result;
};

const CLASS_NAME = "react-calendar-style-overrides";
const UNAVAILABLE_CLASS_NAME = "unavailable";

const StyleOverrides = styled("div")`
  & .${CLASS_NAME}.react-calendar {
    width: 100%;
  }

  & .${CLASS_NAME} .react-calendar__tile {
    padding: 0;
  }

  & .${CLASS_NAME} .react-calendar__tile:disabled {
    background-color: darkred;
    color: black;
  }

  & .${CLASS_NAME} .react-calendar__tile {
    background-color: lightgray;
    color: black;
  }

  & .${CLASS_NAME} .react-calendar__tile--active {
    background-color: lightgreen;
    color: black;
  }

  & .${CLASS_NAME} .${UNAVAILABLE_CLASS_NAME} {
    background-color: black;
    color: black;
  }
`;

const TileContent = ({ date }: TileArgs) => {
  const available = isInAvailablePeriod(date);

  return (
    <Tooltip
      title={
        <FormattedMessage
          id={
            available
              ? "availability.availableInThisTimePeriod"
              : "availability.notAvailableInThisTimePeriod"
          }
        />
      }
      arrow
      enterDelay={500}
      disableInteractive={true}
      placement={"bottom"}
    >
      <Box padding={2}>
        <FormattedDate value={date} month={"long"} />
      </Box>
    </Tooltip>
  );
};

export const Availability: React.FC = () => {
  return (
    <>
      <Title>
        <FormattedMessage id={"availability"} />
      </Title>
      <StyleOverrides>
        <Calendar
          className={CLASS_NAME}
          defaultActiveStartDate={firstAvailableDay()}
          maxDetail={"year"}
          formatMonth={() => ""}
          tileDisabled={tileDisabled}
          tileContent={TileContent}
        />
      </StyleOverrides>
    </>
  );
};
