import React from "react";
import { FormattedMessage } from "react-intl";
import { pixelsPerRem } from "../../util";
import { Title } from "../../component/Title";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";

const StyledBox = styled(Box)`
  white-space: break-spaces;
`;

export const AboutMe: React.FC = () => {
  return (
    <>
      <Title>
        <FormattedMessage id={"about.me"} />
      </Title>
      <Grid container spacing={2}>
        <Grid item sm={8}>
          <StyledBox>
            <FormattedMessage
              id={"about.me.text"}
              values={{
                "scrum-master-link": (parts) => {
                  return (
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        "https://www.scrumalliance.org/community/profile/mwesseler"
                      }
                    >
                      {parts}
                    </Link>
                  );
                },
              }}
            />
          </StyledBox>
        </Grid>
        <Grid
          item
          sm={4}
          maxHeight={15 * pixelsPerRem}
          display={"flex"}
          justifyContent={"center"}
        >
          <img
            src={"/image/profile-photo-without-background.png"}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </Grid>
      </Grid>
    </>
  );
};
