import { createArrayBuffer } from "./array-buffer";

export interface VertexAttribute {
  size: GLint;
  type: GLenum;
  normalized: GLboolean;
  offsetInBytes: GLintptr;
}

export interface VertexAttributes {
  position: VertexAttribute;
  texCoords: VertexAttribute;
}

export interface Mesh {
  vertexBuffer: WebGLBuffer;
  vertexSizeInBytes: number;
  vertexAttributes: VertexAttributes;
}

export const createQuadMesh = (gl: WebGLRenderingContext): Mesh => {
  const vertices = new Float32Array([
    // Bottom left
    -1.0,
    -1.0,
    0.0, // Position
    0.0,
    0.0, // UV
    // Top left
    -1.0,
    1.0,
    0.0, // Position
    0.0,
    1.0, // UV
    // Top right
    1.0,
    1.0,
    0.0, // Position
    1.0,
    1.0, // UV

    // Bottom left
    -1.0,
    -1.0,
    0.0, // Position
    0.0,
    0.0, // UV
    // Top right
    1.0,
    1.0,
    0.0, // Position
    1.0,
    1.0, // UV
    // Bottom right
    1.0,
    -1.0,
    0.0, // Position
    1.0,
    0.0, // UV
  ]);

  return {
    vertexBuffer: createArrayBuffer(gl, vertices),
    vertexSizeInBytes: (3 + 2) * 4, // 5 floats (position + uv) times byte size (4 bytes per float)
    vertexAttributes: {
      position: {
        normalized: false,
        type: gl.FLOAT,
        size: 3,
        offsetInBytes: 0,
      },
      texCoords: {
        normalized: false,
        type: gl.FLOAT,
        size: 2,
        offsetInBytes: 4 * 3,
      },
    },
  };
};

export const enablePositionVertexAttribute = (
  gl: WebGLRenderingContext,
  location: number,
  mesh: Mesh,
) => {
  enableVertexAttribute(
    gl,
    location,
    mesh.vertexSizeInBytes,
    mesh.vertexAttributes.position,
  );
};

export const enableTexCoordsVertexAttribute = (
  gl: WebGLRenderingContext,
  location: number,
  mesh: Mesh,
) => {
  enableVertexAttribute(
    gl,
    location,
    mesh.vertexSizeInBytes,
    mesh.vertexAttributes.texCoords,
  );
};

const enableVertexAttribute = (
  gl: WebGLRenderingContext,
  location: number,
  vertexSizeInBytes: number,
  vertexAttribute: VertexAttribute,
) => {
  gl.vertexAttribPointer(
    location,
    vertexAttribute.size, // Number of components (multiplied with data type to get byte count)
    vertexAttribute.type, // Data type
    vertexAttribute.normalized, // Don't normalize
    vertexSizeInBytes, // Stride = bytes per vertex
    vertexAttribute.offsetInBytes, // Offset bytbytes per vertexes to start reading from
  );

  gl.enableVertexAttribArray(location);
};
