import { Wordcloud } from "@visx/wordcloud";
import { Text } from "@visx/text";
import { pixelsPerRem } from "../../util";
import { useLayoutEffect, useState } from "react";
import { Title } from "../../component/Title";
import { FormattedMessage } from "react-intl";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

interface Technology {
  text: string;
  relativeSize: number;
}

const createWord = (text: string, relativeSize: number): Technology => ({
  text,
  relativeSize,
});

const words: Technology[] = [
  // 5
  createWord("Java", 5),
  createWord("Spring", 5),
  createWord("JavaScript", 5),
  // 3
  createWord("TypeScript", 3),
  createWord("Kotlin", 3),
  // 2
  createWord("TypeScript", 2),
  createWord("Kotlin", 2),
  createWord("GraphQL", 2),
  createWord("PostgreSQL", 2),
  createWord("IntelliJ", 2),
  createWord("VS Code", 2),
  createWord("OpenGL", 2),
  createWord("React", 2),
  createWord("SCRUM", 2),
  // 1
  createWord("Micro Services", 1),
  createWord("Micro Frontends", 1),
  createWord("JIRA", 1),
  createWord("Confluence", 1),
  createWord("Hibernate", 1),
  createWord("RabbitMQ", 2),
  createWord("Material UI", 1),
  createWord("Liferay", 1),
  createWord("Github Copilot", 1),
  createWord("ChatGPT", 1),
  createWord("RabbitMQ", 1),
  createWord("Kubernetes", 1),
  createWord("Gradle", 1),
  createWord("Maven", 1),
  createWord("CSS", 1),
  createWord("Docker", 1),
  createWord("Linux", 1),
  createWord("Playwright", 1),
  createWord("Cypress", 1),
  createWord("Git", 1),
  createWord("SQL", 1),
  createWord("AWS", 1),
  createWord("JavaEE", 1),
  createWord("Github Actions", 1),
];

const staticRandom = () => 0.5;

export const Technologies: React.FC<{}> = () => {
  const theme = useTheme();
  const [element, setElement] = useState<HTMLDivElement | null>(null);

  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    if (element) {
      setWidth(element.clientWidth);
    }
  }, [element]);

  return (
    <>
      <Title>
        <FormattedMessage id={"technologies"} />
      </Title>
      <Box ref={setElement} display={"flex"} justifyContent={"center"}>
        <Wordcloud
          words={words}
          width={width}
          height={pixelsPerRem * 20}
          spiral={"rectangular"}
          rotate={0}
          fontSize={(w) => w.relativeSize * pixelsPerRem * 0.75}
          random={staticRandom}
          padding={pixelsPerRem / 4}
        >
          {(words) =>
            words.map((w, i) => (
              <Text
                key={`${i}`}
                fill={theme.palette.text.primary}
                textAnchor={"middle"}
                transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                fontSize={w.size}
                fontFamily={w.font}
              >
                {w.text}
              </Text>
            ))
          }
        </Wordcloud>
      </Box>
    </>
  );
};
