export const createArrayBuffer = (
  gl: WebGLRenderingContext,
  bufferSource: BufferSource,
) => {
  // Create buffer
  const buffer = gl.createBuffer();

  if (!buffer) {
    throw new Error("Failed to create web gl buffer");
  }

  // Bind buffer
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);

  // Set buffer data
  gl.bufferData(gl.ARRAY_BUFFER, bufferSource, gl.STATIC_DRAW);

  return buffer;
};
