import { css, keyframes } from "@emotion/react";

const fadeInAnimation = keyframes`
    from {
        opacity: 0;
        translate: 0 2rem;
        scale: 0.95;
    }

    25% {
        scale: 1;
        translate: none;
    }

    to {
        opacity: 1;
    }
`;

interface FadeInAnimationProps {
  running: boolean;
  duration: number;
  delay: number;
}

const idleCss = css`
  opacity: 0;
  translate: 0 2rem;
  scale: 0.95;
`;

export const fadeInCss = ({
  running,
  duration,
  delay,
}: FadeInAnimationProps) =>
  running
    ? css`
        opacity: 0;
        animation: ${fadeInAnimation} ${duration}s;
        animation-delay: ${delay}s;
        animation-fill-mode: forwards;
      `
    : idleCss;
