import React from "react";
import { FormattedMessage } from "react-intl";
import { Title } from "../../component/Title";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { SmallTitle } from "../../component/SmallTitle";

const StyledBox = styled(Box)`
  white-space: break-spaces;
`;

export const Disclaimer: React.FC = () => {
  return (
    <>
      <SmallTitle>
        <FormattedMessage id={"disclaimer"} />
      </SmallTitle>
      <StyledBox>
        <small>
          <FormattedMessage id={"disclaimer.text"} />
        </small>
      </StyledBox>
    </>
  );
};
