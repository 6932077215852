import { FormattedMessage } from "react-intl";
import { Title } from "../../component/Title";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const StyledBox = styled(Box)`
  margin-bottom: 1rem;
`;

const BulletPoint: React.FC<{ index: 1 | 2 | 3 }> = ({ index }) => {
  return (
    <StyledBox>
      <Typography variant={"h5"}>
        <FormattedMessage id={`services.bulletpoint.${index}.title`} />
      </Typography>
      <FormattedMessage id={`services.bulletpoint.${index}.text`} />
    </StyledBox>
  );
};

export const ServicesOffered: React.FC = () => {
  return (
    <>
      <Title>
        <FormattedMessage id={"servives.offered"} />
      </Title>
      <BulletPoint index={1} />
      <BulletPoint index={2} />
      <BulletPoint index={3} />
    </>
  );
};
