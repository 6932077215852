import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { loadShaderProgram } from "../../gl/shader";
import { createArrayBuffer } from "../../gl/array-buffer";
import { mat4, vec3, vec4 } from "gl-matrix";
import {
  backgroundFragmentShaderSource,
  fragmentShaderSource,
  postProcessingFragmentShaderSource,
} from "./FragmentShaderSource";
import {
  orthogonalVertexShaderSource,
  vertexShaderSource,
} from "./VertexShaderSource";
import { pixelsPerRem } from "../../util";
import { createTexture, loadTexture } from "../../gl/texture";
import {
  Camera,
  createLookAtMat4,
  createPerspectiveMat4,
} from "../../gl/camera";
import { createFrameBuffer } from "../../gl/frame-buffer";
import {
  createQuadMesh,
  createQuadMesh as createQuadVertices,
  enablePositionVertexAttribute,
  enableTexCoordsVertexAttribute,
} from "../../gl/mesh";

const MAX_PARTICLES = 200;

const MIN_PARTICLE_LIFE_TIME = 5;
const MAX_PARTICLE_LIFE_TIME = 10;

const MIN_PARTICLE_VELOCITY = 0.01;
const MAX_PARTICLE_VELOCITY = 0.02;

const MIN_PARTICLE_ANGULAR_VELOCITY = 0.01;
const MAX_PARTICLE_ANGULAR_VELOCITY = Math.PI * 0.2;

const MAX_PARTICLE_COLOR_VALUE = 0.5;

const BUFFER_SIZE = 1024;

const clamp = (value: number, min: number, max: number) =>
  Math.max(min, Math.min(value, max));
const randomBetween = (min: number, max: number) =>
  min + Math.random() * (max - min);

interface Particle {
  color: vec4;
  position: vec3;
  velocity: number;
  rotationAxis: vec3;
  rotation: number;
  angularVelocity: number;
  scale: number;
  lifeTime: number;
  maxLifeTime: number;
  blendInTime: number;
  fadeOutTime: number;
}

const createParticle = (aspect: number): Particle => {
  const maxLifeTime = randomBetween(
    MIN_PARTICLE_LIFE_TIME,
    MAX_PARTICLE_LIFE_TIME,
  );
  const aHalfOfMaxLifeTime = maxLifeTime / 2;

  const horizontalValue = aspect * 2;
  const verticalValue = (1 / aspect) * 3;

  const position = vec3.fromValues(
    horizontalValue * Math.random() * 2 - horizontalValue,
    verticalValue * Math.random() * 2 - verticalValue,
    horizontalValue * Math.random() * 2 - horizontalValue,
  );

  return {
    color: vec4.fromValues(1.0, 1.0, 1.0, 1.0),
    position: position,
    velocity: randomBetween(MIN_PARTICLE_VELOCITY, MAX_PARTICLE_VELOCITY),
    rotationAxis: vec3.random(vec3.create()),
    rotation: Math.random() * Math.PI * 2,
    angularVelocity: randomBetween(
      MIN_PARTICLE_ANGULAR_VELOCITY,
      MAX_PARTICLE_ANGULAR_VELOCITY,
    ),
    scale: 0.005 + Math.random() * 0.005 * pixelsPerRem,
    lifeTime: 0,
    maxLifeTime,
    blendInTime: Math.random() * aHalfOfMaxLifeTime,
    fadeOutTime: Math.random() * aHalfOfMaxLifeTime,
  };
};

export const BackgroundCanvas: React.FC<{}> = () => {
  const [canvasElement, setCanvasElement] = useState<HTMLCanvasElement | null>(
    null,
  );

  const [canvasWidth, setCanvasWidth] = useState(window.innerWidth);
  const [canvasHeight, setCanvasHeight] = useState(window.innerHeight);

  const scrollValueRef = useRef<number>(0.0);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setCanvasWidth(window.innerWidth);
      setCanvasHeight(window.innerHeight);
    });
  }, []);

  useEffect(() => {
    if (!canvasElement) {
      return;
    }

    const gl = canvasElement.getContext("webgl");

    if (!gl) {
      return;
    }

    // Flip image pixels into the bottom-to-top order that WebGL expects.
    gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);

    // Load texture
    const iconTexture = loadTexture(
      gl,
      "/image/wesseler-software-light-icon.png",
    );

    // Load background shader program
    const backgroundShaderProgram = loadShaderProgram(
      gl,
      orthogonalVertexShaderSource,
      backgroundFragmentShaderSource,
    );
    const backgroundShaderLocations = {
      attrib: {
        position: gl.getAttribLocation(backgroundShaderProgram, "aPosition"),
        texCoords: gl.getAttribLocation(backgroundShaderProgram, "aTexCoords"),
      },
      uniform: {
        projectionMatrix: gl.getUniformLocation(
          backgroundShaderProgram,
          "uProjectionMatrix",
        ),
        scrollYPercentage: gl.getUniformLocation(
          backgroundShaderProgram,
          "uScrollYPercentage",
        ),
      },
    };

    // Load scene shader program
    const sceneShaderProgram = loadShaderProgram(
      gl,
      vertexShaderSource,
      fragmentShaderSource,
    );
    const sceneShaderLocations = {
      attrib: {
        position: gl.getAttribLocation(sceneShaderProgram, "aPosition"),
        texCoords: gl.getAttribLocation(sceneShaderProgram, "aTexCoords"),
      },
      uniform: {
        projectionMatrix: gl.getUniformLocation(
          sceneShaderProgram,
          "uProjectionMatrix",
        ),
        worldMatrix: gl.getUniformLocation(sceneShaderProgram, "uWorldMatrix"),
        viewMatrix: gl.getUniformLocation(sceneShaderProgram, "uViewMatrix"),
        color: gl.getUniformLocation(sceneShaderProgram, "uColor"),
        texture: gl.getUniformLocation(sceneShaderProgram, "uTexture"),
      },
    };

    // Load post-process shader program
    const postProcessingShaderProgram = loadShaderProgram(
      gl,
      orthogonalVertexShaderSource,
      postProcessingFragmentShaderSource,
    );
    const postProcessingLocations = {
      attrib: {
        position: gl.getAttribLocation(
          postProcessingShaderProgram,
          "aPosition",
        ),
        texCoords: gl.getAttribLocation(
          postProcessingShaderProgram,
          "aTexCoords",
        ),
      },
      uniform: {
        projectionMatrix: gl.getUniformLocation(
          postProcessingShaderProgram,
          "uProjectionMatrix",
        ),
        texture: gl.getUniformLocation(postProcessingShaderProgram, "uTexture"),
      },
    };

    // Create view matrix
    const camera: Camera = {
      position: vec3.fromValues(0, 0, 5),
      lookAtPosition: vec3.fromValues(0, 0, 0),
      upDirection: vec3.fromValues(0, 1, 0),
      zNear: 0.1,
      zFar: 10.0,
      fovX: (90 * Math.PI) / 180, // 45 degrees in radians
    };

    // Define matrices
    const perspectiveProjectionMatrix = mat4.create();
    const viewMatrix = mat4.create();

    // Setup gl settings
    gl.enable(gl.DEPTH_TEST); // Enable depth testing
    gl.depthFunc(gl.LEQUAL); // Near things obscure far things
    gl.enable(gl.BLEND); // Enable blending
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA); // Define blending function

    // TODO: Resize framebuffer to canvas size
    // TODO: "Smudge"/blur in post-processing shader
    // TODO: Move center of highlight with scrolling

    // Create quad mesh
    const quadMesh = createQuadMesh(gl);

    // Create frame buffer
    const frameBuffer = createFrameBuffer(gl);

    // Attach texture to frame buffer
    const frameBufferTexture = createTexture(gl);
    gl.bindTexture(gl.TEXTURE_2D, frameBufferTexture);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      BUFFER_SIZE,
      BUFFER_SIZE,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      null,
    );
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

    // Create particles
    const particles: Particle[] = Array.from({ length: MAX_PARTICLES }, () =>
      createParticle(gl.canvas.width / gl.canvas.height),
    );

    // Frame relevant variables
    let lastTimeInMilliseconds = 0;
    let loop = true;

    const renderFrame = (timeInMilliseconds: number = 0) => {
      const deltaSeconds = (timeInMilliseconds - lastTimeInMilliseconds) / 1000;

      gl.clearColor(49 / 1000, 45 / 1000, 50 / 1000, 1);

      // ############################
      // ##### Update Particles #####
      // ############################

      particles.forEach((particle, index) => {
        // Update position
        const deltaPosition = vec3.scale(
          vec3.create(),
          camera.upDirection,
          particle.velocity * deltaSeconds,
        );
        vec3.add(particle.position, particle.position, deltaPosition);

        // Update rotation
        const deltaRotation = particle.angularVelocity * deltaSeconds;
        particle.rotation += deltaRotation;

        // Update lifetime
        particle.lifeTime += deltaSeconds;
      });

      // ###########################
      // ##### Update Matrices #####
      // ###########################

      // Update orthogonal projection matrix
      const orthogonalProjectionMatrix = mat4.create();
      gl.viewport(0, 0, BUFFER_SIZE, BUFFER_SIZE); // Viewport maps view space (-1 to +1 to the canvas/frame buffer)
      mat4.ortho(orthogonalProjectionMatrix, -1, 1, -1, 1, -1, 1);

      // Update perspective projection matrix
      const aspect = gl.canvas.width / gl.canvas.height;
      createPerspectiveMat4(perspectiveProjectionMatrix, camera, aspect);

      // Update view matrix
      const targetScrollY = window.scrollY;
      const lastScrollY = scrollValueRef.current;
      const diffScrollY = targetScrollY - lastScrollY;
      scrollValueRef.current += diffScrollY * deltaSeconds * 2;
      const scrollYPercentage = scrollValueRef.current / window.innerHeight;

      vec3.set(camera.position, 0, 0, 5);
      vec3.rotateY(
        camera.position,
        camera.position,
        vec3.zero(vec3.create()),
        scrollValueRef.current / (500 * pixelsPerRem * aspect),
      );
      createLookAtMat4(viewMatrix, camera);

      // ###############################
      // ##### Prepare Framebuffer #####
      // ###############################

      // Bind frame buffer
      gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffer);
      gl.framebufferTexture2D(
        gl.FRAMEBUFFER,
        gl.COLOR_ATTACHMENT0,
        gl.TEXTURE_2D,
        frameBufferTexture,
        0,
      );

      // Clear framebuffer
      gl.clearDepth(1);
      gl.clearColor(1, 0, 0, 1);
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

      // #############################
      // ##### Render Background #####
      // #############################

      // Activate background shader program
      gl.useProgram(backgroundShaderProgram);
      gl.uniformMatrix4fv(
        backgroundShaderLocations.uniform.projectionMatrix,
        false,
        orthogonalProjectionMatrix,
      );
      gl.uniform1f(
        backgroundShaderLocations.uniform.scrollYPercentage,
        scrollYPercentage,
      );

      // Draw background quad
      enablePositionVertexAttribute(
        gl,
        backgroundShaderLocations.attrib.position,
        quadMesh,
      );
      enableTexCoordsVertexAttribute(
        gl,
        backgroundShaderLocations.attrib.texCoords,
        quadMesh,
      );
      gl.drawArrays(gl.TRIANGLES, 0, 6);

      // ########################
      // ##### Render Scene #####
      // ########################

      // Activate scene shader program
      gl.useProgram(sceneShaderProgram);
      gl.uniformMatrix4fv(
        sceneShaderLocations.uniform.projectionMatrix,
        false,
        perspectiveProjectionMatrix,
      );
      gl.uniformMatrix4fv(
        sceneShaderLocations.uniform.viewMatrix,
        false,
        viewMatrix,
      );

      // Bind logo texture
      gl.activeTexture(gl.TEXTURE0);
      gl.bindTexture(gl.TEXTURE_2D, iconTexture);
      gl.uniform1i(sceneShaderLocations.uniform.texture, 0);

      // Render particles
      const worldMatrix = mat4.create();
      gl.bindBuffer(gl.ARRAY_BUFFER, quadMesh.vertexBuffer);

      particles.forEach((particle) => {
        // Update world matrix
        mat4.identity(worldMatrix);
        mat4.translate(worldMatrix, worldMatrix, particle.position);
        mat4.rotate(
          worldMatrix,
          worldMatrix,
          particle.rotation,
          particle.rotationAxis,
        );
        mat4.scale(
          worldMatrix,
          worldMatrix,
          vec3.fromValues(particle.scale, particle.scale, particle.scale),
        );
        gl.uniformMatrix4fv(
          sceneShaderLocations.uniform.worldMatrix,
          false,
          worldMatrix,
        );

        // Update color
        const timeLeftToLive = particle.maxLifeTime - particle.lifeTime;
        let blendValue = 0;

        if (particle.lifeTime < particle.blendInTime) {
          // Blend in
          blendValue = clamp(
            particle.lifeTime / particle.blendInTime,
            0,
            MAX_PARTICLE_COLOR_VALUE,
          );
        } else if (timeLeftToLive <= particle.fadeOutTime) {
          // Fade out
          blendValue = clamp(
            timeLeftToLive / particle.fadeOutTime,
            0,
            MAX_PARTICLE_COLOR_VALUE,
          );
        } else {
          // Fully visible
          blendValue = MAX_PARTICLE_COLOR_VALUE;
        }

        const color = vec4.scale(vec4.create(), particle.color, blendValue);

        gl.uniform4fv(sceneShaderLocations.uniform.color, color);

        // Draw particle quad
        enablePositionVertexAttribute(
          gl,
          sceneShaderLocations.attrib.position,
          quadMesh,
        );
        enableTexCoordsVertexAttribute(
          gl,
          sceneShaderLocations.attrib.texCoords,
          quadMesh,
        );
        gl.drawArrays(gl.TRIANGLES, 0, 6);
      });

      // ##############################
      // ##### Prepare Backbuffer #####
      // ##############################

      gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

      // Unbind frame buffer
      gl.bindFramebuffer(gl.FRAMEBUFFER, null);

      // Activate post-process shader program
      gl.useProgram(postProcessingShaderProgram);
      gl.uniformMatrix4fv(
        postProcessingLocations.uniform.projectionMatrix,
        false,
        orthogonalProjectionMatrix,
      );

      // Bind framebuffer texture
      gl.activeTexture(gl.TEXTURE0);
      gl.bindTexture(gl.TEXTURE_2D, frameBufferTexture);
      gl.uniform1i(postProcessingLocations.uniform.texture, 0);

      // Render post-process to canvas
      enablePositionVertexAttribute(
        gl,
        postProcessingLocations.attrib.position,
        quadMesh,
      );
      enableTexCoordsVertexAttribute(
        gl,
        postProcessingLocations.attrib.texCoords,
        quadMesh,
      );
      gl.drawArrays(gl.TRIANGLES, 0, 6);

      // Renew particles
      particles.forEach((particle, index) => {
        if (particle.lifeTime > particle.maxLifeTime) {
          particles[index] = createParticle(aspect);
        }
      });

      // Request next frame
      if (loop) {
        lastTimeInMilliseconds = timeInMilliseconds;
        window.requestAnimationFrame(renderFrame);
      }
    };

    renderFrame();

    return () => {
      // Break render loop on unmount
      loop = false;
    };
  }, [canvasElement]);

  return (
    <canvas
      ref={setCanvasElement}
      width={canvasWidth}
      height={canvasHeight}
      style={{
        width: "100%",
        minHeight: "100vh",
        height: "100%",
        position: "fixed",
        zIndex: -1,
      }}
    />
  );
};
