import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { theme } from "./theme";
import de from "./translation/de.json";
import en from "./translation/en.json";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "@mui/material/styles";

// Load roboto font
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import CssBaseline from "@mui/material/CssBaseline";

// React multi carousel css
import "react-multi-carousel/lib/styles.css";

// Translations
const resolveMessages = (locale: string) => {
  if (locale.startsWith("de")) {
    return de;
  }

  return en;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <IntlProvider
      messages={resolveMessages(navigator.language)}
      locale={navigator.language}
      defaultLocale={"en"}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </IntlProvider>
  </React.StrictMode>,
);
